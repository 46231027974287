import React from "react"
import Layout from "../components/layout"
import { navTo } from '../utils/navigation'

const IndexPage = () => {
  React.useEffect(navTo.introduction_1, [])
  return <Layout.ColumnLayout flex="text-center">
    Loading...
  </Layout.ColumnLayout>
}
export default IndexPage
